import PropTypes from 'prop-types'
import Router from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { AuthProvider } from '../hooks/auth'

import '../styles/global.css'
import '../styles/common/main.scss'
import '../styles/bantrab/main.scss'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function Crehana ({ Component, pageProps }) {
  return <AuthProvider>
    <Component {...pageProps} />
  </AuthProvider>
}

Crehana.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any
}
export default Crehana
